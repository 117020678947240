import Swiper from "swiper/bundle";
import "swiper/css/bundle";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class Slide {
    constructor() {
        this.init();
    }

    init() {
        this.setSwiper();
    }

    setSwiper() {

        [...document.querySelectorAll(".js-top-swiper")].forEach(($slide) => {

            let category = $slide.getAttribute("data-swipertype");
            let slideper = category === "event" ? 1.2 : 1.5;

            const opts = {
                centeredSlides: true,
                slidesPerView: slideper,
                spaceBetween: 10,
                speed: 700,
                loop: true,

                observer: true,
                observeParents: true,

                navigation: {
                    nextEl: $slide.querySelector(".topslide__ui__btn.-next"),
                    prevEl: $slide.querySelector(".topslide__ui__btn.-prev"),
                },
            };

            const swiper = new Swiper($slide, opts);
        });
        
    }
}
