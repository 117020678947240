import Swiper from "swiper/bundle";
import "swiper/css/bundle";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class Bnr {
    constructor() {
        this.init();
    }

    init() {
        this.setSwiper();
    }

    setSwiper() {
        const option = {
            autoplay: { delay: 5000 },
            centeredSlides: true,
            slidesPerView: "auto",
            spaceBetween: 0,
            loop: true,

            breakpoints: {
                835: {
                    slidesPerView: 2.2,
                    /* scrollbar: {
                        el: ".swiper-scrollbar",
                        draggable: true,
                    }, */
                },
            },

            pagination: {
                el: ".swiper-pagination",
                clickable: true,
                draggable: true,
            },
        };

        const swiper = new Swiper(".mainvisual__bnr", option);
    }
}
