import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class Share {
    constructor() {
        this.init();
    }

    init() {
        this.onScroll();
    }

    onScroll() {
        const shareelm = document.querySelector(".l-header__share.-top");        

        ScrollTrigger.create({
            trigger: document.querySelector(".whats"),
            start: "top bottom",
            onEnter: () => {
                shareelm.classList.add("-show");
            },
            onLeaveBack: () => {
                shareelm.classList.remove("-show");
            },
        });
    }
}