export default class Loader {
    constructor() {
        this.init();
    }

    getParam() {
        let param = location.search.slice(1).split("&"),
            output = {};

        for (let i = 0; param[i]; i++) {
            let j = param[i].split("=");
            output[j[0]] = j[1];
        }

        return output;
    }

    init() {
        const param = this.getParam();

        if (param.skip) {
            // イントロスキップ
            const loader = document.querySelector(".l-loader");
            loader.remove();
            document.body.classList.add("-introend");
            document.body.classList.remove("-lock");
        } else {
            setTimeout(() => {
                document.body.classList.add("-introend");
            }, 2700);

            setTimeout(() => {
                document.body.classList.remove("-lock");
            }, 2700);
        }
    }
}
