import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Loader from "./loader.es6";
import Bnr from "./bnr.es6";
import Slide from "./slide.es6";
import Member from "./member.es6";
import Share from "./share.es6";

gsap.registerPlugin(ScrollTrigger);
export default class Top {
    constructor() {
        this.Loader = new Loader();
        this.bnr = new Bnr();
        this.slide = new Slide();
        this.member = new Member();
        this.share = new Share();
        // this.init();
    }

    init() {}

    setScroller() {}
}
