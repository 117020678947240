export default class Member {
    constructor() {
        this.init();
    }

    init() {
        this.clickSlide();
    }

    clickSlide() {
        const container = document.querySelector(".js-member-block");
        const prevbtn = document.querySelector(".js-memberbnt-prev");
        const nextvbtn = document.querySelector(".js-memberbnt-next");

        const movewidth = container.offsetWidth / 3;


        let animationFrameId = null;

        function easeInOutCubic(t) {
            if (t < 0.5) {
                return 4 * t * t * t;
            } else {
                return 1 - Math.pow(-2 * t + 2, 3) / 2;
            }
        }

        function smoothScroll(targetScrollLeft) {
            const startScrollLeft = container.scrollLeft;
            const scrollDistance = targetScrollLeft - startScrollLeft;
            const startTime = performance.now();

            function animate(time) {
                const elapsed = time - startTime;
                const progress = Math.min(elapsed / 300, 1); // 300ms duration
                const easedProgress = easeInOutCubic(progress);

                container.scrollLeft =
                    startScrollLeft + easedProgress * scrollDistance;

                if (progress < 1) {
                    animationFrameId = requestAnimationFrame(animate);
                } else {
                    cancelAnimationFrame(animationFrameId);
                    animationFrameId = null;
                }
            }

            if (animationFrameId) {
                cancelAnimationFrame(animationFrameId);
            }
            animationFrameId = requestAnimationFrame(animate);
        }

        prevbtn.addEventListener("click", (e) => {
            const newScrollLeft = container.scrollLeft - movewidth;
            smoothScroll(Math.max(newScrollLeft, 0));
        });

        nextvbtn.addEventListener("click", (e) => {
            const maxScrollLeft = container.scrollWidth - container.offsetWidth;
            const newScrollLeft = container.scrollLeft + movewidth;
            smoothScroll(Math.min(newScrollLeft, maxScrollLeft));
        });
    }
}
